.filterContainerMain 
.MuiContainer-root-2144 
.MuiContainer-maxWidthLg-764{
    background-color: none !important;
    color : "none !important"
  }

  .btn{
    box-sizing: border-box;
    padding: 0.5em;
    width :6em;
    cursor: pointer;
    border-radius: 0.5em;
   
}