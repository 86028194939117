.grid-Part {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 60%;
    max-width: 400px;
    padding: 0.6em;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    position: absolute;
    top: 14%;
    left: 50%; 
    z-index: 9999;
    transform: translateX(-50%);
     /* background-color: rgb(246, 247, 249); */
     background-color: #ffffff;
     font-size: 0.9rem;
}

.headerPart {
    display: flex;
    justify-content: space-between;
    padding: 4%;
    box-shadow: 0px 1px 0px 1px rgba(213,207,207,0.22);
}

.middleText {
    display: flex;
    flex-direction: column;
    padding: 4%;
}
.middleText1{
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    max-width: 500px;
    overflow: hidden;
}
.textArrowFlex{
    display: flex;
}
.ArrowFlex{
    display: flex; 
    flex-direction: row;
    align-items: center;
    padding: 5%;
}
.ArrowFlex1{
    margin-right: 1em;
    padding: 5%;
}
.ArrowFlex1:hover{
    /* background-color: rgb(250, 245, 243); */
    border-radius: 25%;
}
.ArrowFlex2:hover{
    background-color: rgba(240, 233, 230, 0.6);
    border-radius: 25%;
}
.ArrowFlex2{
    padding: 5%; 
    margin-right: 13em; 
}

.ArrowFlex:hover{
    cursor: pointer;
   
}
.ArrowFlex1:hover{
    cursor: pointer;
}
.btn{
    box-sizing: border-box;
    padding: 0.5em;
    width :6em;
    cursor: pointer;
    border-radius: 0.5em;
   
}
.w-6{
    width : 6
  }
.default_btn:hover{
    background-color: rgb(250, 245, 243);
}

.date-done-icon{
    position: absolute;
    right: 0 !important;
}

.authorDoneIcon{
    position: absolute;
    right: 0 !important;
    top: 18px;
  }
.subjectDoneIcon{
    position: absolute;
    right: 0 !important;
  }
  .middleText1{
    position: relative;
  }