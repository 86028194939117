.MuiButton-root{
    text-transform: capitalize !important;
}

.MuiInputBase-input :hover{
    border-bottom: none !important;
}
.abc{
    background-color: #262626;height: min-content;
    padding-bottom: 10px;
    padding-right: 10px;}





