.MuiPaper-elevation1 {
    box-shadow: none !important;
}
.css-cursor-18t94o4{
    margin-left: 130px;
}
.w-6{
    width : 20
  }
/* .r-marginLeft{
    margin-left: 0px !important;
}
.r-justifyContent{
    justify-content: center !important;
} */
/* .MuiAlert-root {
    margin-left: 200px;
   } */
/* .css-view{
    display: flex;
    justify-content: center !important;
    align-items: center;
} */