.MuiInput-underline-3538::before {
  transition: none !important;
  border-bottom: none !important;
}

.MuiInput-underline-3538::after {
  transition: none !important;
  border-bottom: none !important;
}

.MuiMenu-list-3643 {
  background-color: white !important;
}

.MuiMenu-paper-3642 {
  top: 150px !important;
}
.MuiInputBase-root {
  position: none;
}

.MuiList-padding-6098 {
  padding-top: none !important;
  padding-bottom: none !important;
  /* padding: none !important; */
  /* padding: 0; */
}
.MuiInput-underline-5378::before {
  border-bottom: none;
}
